/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { CoreAppState } from '@ws1c/intelligence-core/store';
import { NavigationActions, NavigationSelectors } from '@ws1c/intelligence-core/store/navigation';
import { BlockerStatus } from '@ws1c/intelligence-models';

/**
 * Confirms deactivation
 * @param {any} _component
 * @param {ActivatedRouteSnapshot} _currentRoute
 * @param {RouterStateSnapshot} _currentState
 * @param {RouterStateSnapshot} _nextState
 * @returns {Observable<boolean>}
 * @export
 */
export function confirmDeactivationGuard(
  _component: any,
  _currentRoute: ActivatedRouteSnapshot,
  _currentState: RouterStateSnapshot,
  _nextState: RouterStateSnapshot,
): Observable<boolean> {
  const store = inject(Store<CoreAppState>);
  return store.select(NavigationSelectors.getNavigationBlockerStatus).pipe(
    take(1),
    switchMap((status: BlockerStatus) => {
      switch (status) {
        case BlockerStatus.SKIP_NEXT_BLOCK:
          store.dispatch(NavigationActions.setBlockerStatus({ blockerStatus: BlockerStatus.BLOCKING }));
          return of(true);
        // APPROVED and PENDING_APPROVAL state here is stale; get new approval
        case BlockerStatus.APPROVED:
        case BlockerStatus.PENDING_APPROVAL:
        case BlockerStatus.BLOCKING:
          store.dispatch(NavigationActions.setBlockerStatus({ blockerStatus: BlockerStatus.PENDING_APPROVAL }));
          return store.select(NavigationSelectors.getNavigationBlockerStatus).pipe(
            filter((blockerStatus: BlockerStatus) => blockerStatus !== BlockerStatus.PENDING_APPROVAL),
            map((blockerStatus: BlockerStatus) => blockerStatus === BlockerStatus.APPROVED),
            take(1),
          );
        default:
          return of(false);
      }
    }),
  );
}
